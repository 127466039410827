@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Outfit:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Outfit", sans-serif;
  background-color: #f3f3f6;
}

/* scrollbar Styles */
.element-without-scrollbar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #000168;
}

/* Custom focus style for DatePicker */
/* .ant-picker-range.ant-picker-focused {
  border-color: red !important;
  box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
} */

/* .ant-picker-outlined {
  border-color: red !important;
}

.ant-picker-active-bar {
  background: red !important;
} */

:where(.css-dev-only-do-not-override-apn68).ant-picker .ant-picker-suffix {
  color: black !important;
}

/* Styles for screens below 320px (small mobile screens) */
@media (max-width: 320px) {
  .ant-picker-panels {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    justify-items: center;
  }

  .ant-picker-dropdown {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    justify-items: center;
  }
}

/* Styles for screens below 768px (tablet and mobile screens) */
@media (max-width: 768px) {
  .ant-picker-panels {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    justify-items: center;
  }

  .ant-picker-dropdown {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    justify-items: center;
  }
}

/* plane animation */
.plane-animation {
  top: 50%;
  left: 0px;
  transform: translateY(100%);
  font-size: 2rem;
  animation: fly-plane 10s ease-in-out infinite;
}

@keyframes fly-plane {
  0% {
    transform: translateX(-100px) translateY(-100%);
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    transform: translateX(100%) translateY(-50%);
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateX(215%) translateY(-100%);
    opacity: 0;
  }
}

/* logo font style */
.logo-font {
  font-family: "Noto Serif", serif;
}
